var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_ujo_tracking_report") } },
    [
      _c(
        "a-form-model",
        {
          ref: "form",
          attrs: {
            model: _vm.state,
            "label-align": "left",
            "wrapper-col": { span: 12 },
            "label-col": { span: 12 },
            "data-testid": "ujo-tracking-report-form"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.handleSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "a-row",
            { attrs: { type: "flex", gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: _vm.isShowAdvancedFilter ? 6 : 8 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_ujo_number"),
                        prop: "ujoNumber"
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          "allow-clear": "",
                          placeholder: _vm.$t("common.insert-text", {
                            text: _vm.$t("lbl_ujo_number")
                          }),
                          "data-testid": "ujo-tracking-report-ujo-number"
                        },
                        model: {
                          value: _vm.state.ujoNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.state, "ujoNumber", $$v)
                          },
                          expression: "state.ujoNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isShowAdvancedFilter,
                          expression: "isShowAdvancedFilter"
                        }
                      ],
                      attrs: {
                        label: _vm.$t("lbl_unit_code"),
                        prop: "unitCode"
                      }
                    },
                    [
                      _c("a-select", {
                        attrs: {
                          "label-in-value": "",
                          options: _vm.truckOptions,
                          loading: _vm.loading.unitCode,
                          "filter-option": false,
                          "show-search": "",
                          "allow-clear": "",
                          "dropdown-match-select-width": false,
                          placeholder: _vm.$t("common.select-text", {
                            text: _vm.$t("lbl_unit_code")
                          }),
                          "data-testid": "ujo-tracking-report-unit-code"
                        },
                        on: {
                          search: _vm.onSearchTruck,
                          change: _vm.onChangeUnit
                        },
                        model: {
                          value: _vm.state.unitCode,
                          callback: function($$v) {
                            _vm.$set(_vm.state, "unitCode", $$v)
                          },
                          expression: "state.unitCode"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isShowAdvancedFilter,
                          expression: "isShowAdvancedFilter"
                        }
                      ],
                      attrs: {
                        label: _vm.$t("lbl_payment_date"),
                        prop: "paymentDate"
                      }
                    },
                    [
                      _c("a-range-picker", {
                        attrs: {
                          placeholder: [
                            _vm.$t("lbl_start_date"),
                            _vm.$t("lbl_end_date")
                          ],
                          format: _vm.DEFAULT_DATE_FORMAT,
                          "data-testid": "ujo-tracking-report-payment-date"
                        },
                        model: {
                          value: _vm.state.paymentDate,
                          callback: function($$v) {
                            _vm.$set(_vm.state, "paymentDate", $$v)
                          },
                          expression: "state.paymentDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isShowAdvancedFilter,
                          expression: "isShowAdvancedFilter"
                        }
                      ],
                      attrs: {
                        label: _vm.$t("lbl_settlement_date"),
                        prop: "settlementDate"
                      }
                    },
                    [
                      _c("a-range-picker", {
                        attrs: {
                          "data-testid": "ujo-tracking-report-settlement-date",
                          placeholder: [
                            _vm.$t("lbl_start_date"),
                            _vm.$t("lbl_end_date")
                          ],
                          format: _vm.DEFAULT_DATE_FORMAT
                        },
                        model: {
                          value: _vm.state.settlementDate,
                          callback: function($$v) {
                            _vm.$set(_vm.state, "settlementDate", $$v)
                          },
                          expression: "state.settlementDate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: _vm.isShowAdvancedFilter ? 6 : 8 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_shipment_id"),
                        prop: "shipmentId",
                        "label-align": "right"
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          "allow-clear": "",
                          "data-testid": "ujo-tracking-report-shipment-id",
                          placeholder: _vm.$t("common.insert-text", {
                            text: _vm.$t("lbl_shipment_id")
                          })
                        },
                        model: {
                          value: _vm.state.shipmentId,
                          callback: function($$v) {
                            _vm.$set(_vm.state, "shipmentId", $$v)
                          },
                          expression: "state.shipmentId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isShowAdvancedFilter,
                          expression: "isShowAdvancedFilter"
                        }
                      ],
                      attrs: {
                        label: _vm.$t("lbl_sales_order_date"),
                        prop: "piDate",
                        "label-align": "right"
                      }
                    },
                    [
                      _c("a-range-picker", {
                        attrs: {
                          "data-testid": "ujo-tracking-report-pi-date",
                          placeholder: [
                            _vm.$t("lbl_start_date"),
                            _vm.$t("lbl_end_date")
                          ],
                          format: _vm.DEFAULT_DATE_FORMAT
                        },
                        model: {
                          value: _vm.state.piDate,
                          callback: function($$v) {
                            _vm.$set(_vm.state, "piDate", $$v)
                          },
                          expression: "state.piDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isShowAdvancedFilter,
                          expression: "isShowAdvancedFilter"
                        }
                      ],
                      attrs: {
                        label: _vm.$t("lbl_invoice_ar_date"),
                        prop: "invoiceDate",
                        "label-align": "right"
                      }
                    },
                    [
                      _c("a-range-picker", {
                        attrs: {
                          placeholder: [
                            _vm.$t("lbl_start_date"),
                            _vm.$t("lbl_end_date")
                          ],
                          format: _vm.DEFAULT_DATE_FORMAT,
                          "data-testid": "ujo-tracking-report-invoice-date"
                        },
                        model: {
                          value: _vm.state.invoiceDate,
                          callback: function($$v) {
                            _vm.$set(_vm.state, "invoiceDate", $$v)
                          },
                          expression: "state.invoiceDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isShowAdvancedFilter,
                          expression: "isShowAdvancedFilter"
                        }
                      ],
                      attrs: {
                        label: _vm.$t("lbl_receipt_date"),
                        prop: "receiptDate",
                        "label-align": "right"
                      }
                    },
                    [
                      _c("a-range-picker", {
                        attrs: {
                          placeholder: [
                            _vm.$t("lbl_start_date"),
                            _vm.$t("lbl_end_date")
                          ],
                          format: _vm.DEFAULT_DATE_FORMAT,
                          "data-testid": "ujo-tracking-report-receipt-date"
                        },
                        model: {
                          value: _vm.state.receiptDate,
                          callback: function($$v) {
                            _vm.$set(_vm.state, "receiptDate", $$v)
                          },
                          expression: "state.receiptDate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: _vm.isShowAdvancedFilter ? 6 : 8 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_customer"),
                        prop: "customer",
                        "label-align": "right"
                      }
                    },
                    [
                      _c("a-select", {
                        attrs: {
                          "label-in-value": "",
                          options: _vm.customerOptions,
                          loading: _vm.loading.customer,
                          "filter-option": false,
                          "show-search": "",
                          "allow-clear": "",
                          "dropdown-match-select-width": false,
                          placeholder: _vm.$t("common.select-text", {
                            text: _vm.$t("lbl_customer")
                          }),
                          "data-testid": "ujo-tracking-report-customer"
                        },
                        on: {
                          search: _vm.onSearchCustomer,
                          change: _vm.onChangeCustomer
                        },
                        model: {
                          value: _vm.state.customer,
                          callback: function($$v) {
                            _vm.$set(_vm.state, "customer", $$v)
                          },
                          expression: "state.customer"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isShowAdvancedFilter,
                          expression: "isShowAdvancedFilter"
                        }
                      ],
                      attrs: {
                        label: _vm.$t("lbl_settlement"),
                        prop: "bySettlement",
                        "label-align": "right"
                      }
                    },
                    [
                      _c("a-select", {
                        attrs: {
                          "allow-clear": "",
                          "data-testid": "ujo-tracking-report-is-settlement",
                          options: _vm.booleanOptions,
                          placeholder: _vm.$t("common.yes-no")
                        },
                        model: {
                          value: _vm.state.bySettlement,
                          callback: function($$v) {
                            _vm.$set(_vm.state, "bySettlement", $$v)
                          },
                          expression: "state.bySettlement"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isShowAdvancedFilter,
                          expression: "isShowAdvancedFilter"
                        }
                      ],
                      attrs: {
                        label: _vm.$t("lbl_sales_order"),
                        prop: "bySalesOrder",
                        "label-align": "right"
                      }
                    },
                    [
                      _c("a-select", {
                        attrs: {
                          "allow-clear": "",
                          options: _vm.booleanOptions,
                          placeholder: _vm.$t("common.yes-no"),
                          "data-testid": "ujo-tracking-report-is-sales-order"
                        },
                        model: {
                          value: _vm.state.bySalesOrder,
                          callback: function($$v) {
                            _vm.$set(_vm.state, "bySalesOrder", $$v)
                          },
                          expression: "state.bySalesOrder"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isShowAdvancedFilter,
                          expression: "isShowAdvancedFilter"
                        }
                      ],
                      attrs: {
                        label: _vm.$t("lbl_invoice_ar"),
                        prop: "byInvoiceAr",
                        "label-align": "right"
                      }
                    },
                    [
                      _c("a-select", {
                        attrs: {
                          "allow-clear": "",
                          options: _vm.booleanOptions,
                          placeholder: _vm.$t("common.yes-no"),
                          "data-testid": "ujo-tracking-report-is-invoice-ar"
                        },
                        model: {
                          value: _vm.state.byInvoiceAr,
                          callback: function($$v) {
                            _vm.$set(_vm.state, "byInvoiceAr", $$v)
                          },
                          expression: "state.byInvoiceAr"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isShowAdvancedFilter,
                      expression: "isShowAdvancedFilter"
                    }
                  ],
                  attrs: { span: 6 }
                },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_ar_receipt"),
                        prop: "byArReceipt",
                        "label-align": "right"
                      }
                    },
                    [
                      _c("a-select", {
                        attrs: {
                          "allow-clear": "",
                          options: _vm.booleanOptions,
                          "data-testid": "ujo-tracking-report-is-ar-receipt",
                          placeholder: _vm.$t("common.yes-no")
                        },
                        model: {
                          value: _vm.state.byArReceipt,
                          callback: function($$v) {
                            _vm.$set(_vm.state, "byArReceipt", $$v)
                          },
                          expression: "state.byArReceipt"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_sales_name"),
                        prop: "sales",
                        "label-align": "right"
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          "allow-clear": "",
                          placeholder: _vm.$t("common.insert-text", {
                            text: _vm.$t("lbl_sales_name")
                          }),
                          "data-testid": "ujo-tracking-report-is-sales-name"
                        },
                        model: {
                          value: _vm.state.sales,
                          callback: function($$v) {
                            _vm.$set(_vm.state, "sales", $$v)
                          },
                          expression: "state.sales"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            {
              attrs: {
                type: "flex",
                justify: "space-between",
                gutter: [16, 16]
              }
            },
            [
              _c(
                "a-col",
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            "html-type": "button",
                            "data-testid": "ujo-tracking-report-reset"
                          },
                          on: { click: _vm.handleReset }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_reset")) + " ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.loading.find,
                            "data-testid": "ujo-tracking-report-find",
                            "html-type": "submit"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        icon: _vm.isShowAdvancedFilter ? "up" : "down",
                        "data-testid": "ujo-tracking-report-advanced-filter"
                      },
                      on: {
                        click: function($event) {
                          return _vm.toggleAdvancedFilter(
                            !_vm.isShowAdvancedFilter
                          )
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_advanced_filter")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("a-table", {
        staticClass: "mt-3",
        attrs: {
          "data-source": _vm.dataSource,
          columns: _vm.columns,
          size: "small",
          loading: _vm.loading.find,
          scroll: { x: 2500 },
          pagination: {
            showTotal: function(total) {
              return _vm.$t("lbl_total_items", { total: total })
            }
          },
          "row-class-name": function(_record, index) {
            return (index % 2 ? "bg-white" : "bg-gray-light") + " pointer"
          },
          "expand-row-by-click": "",
          "default-expand-all-rows": "",
          "data-testid": "ujo-tracking-report-table"
        },
        scopedSlots: _vm._u([
          {
            key: "expandedRowRender",
            fn: function(record) {
              return [
                _c(
                  "a-tabs",
                  { attrs: { type: "card" } },
                  [
                    _c(
                      "a-tab-pane",
                      {
                        key: "settlement",
                        attrs: { tab: _vm.$t("lbl_payment_settlement") }
                      },
                      [
                        _c("a-table", {
                          staticStyle: { width: "1400px" },
                          attrs: {
                            "data-source": record.settlements,
                            columns: _vm.columnsSettlement,
                            size: "small",
                            pagination: {
                              showTotal: function(total) {
                                return _vm.$t("lbl_total_items", {
                                  total: total
                                })
                              }
                            },
                            "row-class-name": function(_record, index) {
                              return index % 2 ? "bg-white" : "bg-gray-light"
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-tab-pane",
                      {
                        key: "sales-order",
                        attrs: { tab: _vm.$t("lbl_pi_sales_order") }
                      },
                      [
                        _c("a-table", {
                          staticStyle: { width: "800px" },
                          attrs: {
                            "data-source": record.salesOrders,
                            columns: _vm.columnsSalesOrder,
                            size: "small",
                            pagination: {
                              showTotal: function(total) {
                                return _vm.$t("lbl_total_items", {
                                  total: total
                                })
                              }
                            },
                            "row-class-name": function(_record, index) {
                              return index % 2 ? "bg-white" : "bg-gray-light"
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-tab-pane",
                      {
                        key: "invoice-ar",
                        attrs: { tab: _vm.$t("lbl_invoice_ar") }
                      },
                      [
                        _c("a-table", {
                          staticStyle: { width: "800px" },
                          attrs: {
                            "data-source": record.invoiceAr,
                            columns: _vm.columnsInvoice,
                            size: "small",
                            pagination: {
                              showTotal: function(total) {
                                return _vm.$t("lbl_total_items", {
                                  total: total
                                })
                              }
                            },
                            "row-class-name": function(_record, index) {
                              return index % 2 ? "bg-white" : "bg-gray-light"
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-tab-pane",
                      {
                        key: "ar-receipt",
                        attrs: { tab: _vm.$t("lbl_ar_receipt") }
                      },
                      [
                        _c("a-table", {
                          staticStyle: { width: "800px" },
                          attrs: {
                            "data-source": record.invoiceReceipts,
                            columns: _vm.columnsInvoiceReceipt,
                            size: "small",
                            pagination: {
                              showTotal: function(total) {
                                return _vm.$t("lbl_total_items", {
                                  total: total
                                })
                              }
                            },
                            "row-class-name": function(_record, index) {
                              return index % 2 ? "bg-white" : "bg-gray-light"
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _c(
        "a-row",
        { staticClass: "mt-3", attrs: { type: "flex", justify: "end" } },
        [
          _c(
            "a-col",
            [
              _c(
                "a-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.loading.download,
                    "data-testid": "ujo-tracking-report-download"
                  },
                  on: { click: _vm.handleDownload }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }