


























































































































































































































































































































































import { SearchBuilder } from "@/builder";
import currencyFilter from "@/filters/currency.filter";
import dateFormat from "@/filters/date.filter";
import { toTitlecase } from "@/helpers/common";
import { debounceProcess } from "@/helpers/debounce";
import { generateUUID } from "@/helpers/uuid";
import { useAsset, useBlob, useContactData, useDate } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  UjoTrackingReportDataView,
  UjoTrackingReportDto,
  UjoTrackingReportState,
} from "@/models/interface/ujo-tracking-report";
import { ujoTrackingReportService } from "@/services/ujo-tracking-report.service";
import { ColumnDef, LabelInValue } from "@/types";
import { FormModel } from "ant-design-vue";
import { Moment } from "moment";
import Vue from "vue";

export default Vue.extend({
  name: "UjoTrackingReport",
  data() {
    this.onSearchCustomer = debounceProcess(this.onSearchCustomer, 500);
    this.onSearchTruck = debounceProcess(this.onSearchTruck, 500);
    return {
      DEFAULT_DATE_FORMAT,
      form: {} as FormModel,
      state: {
        ujoNumber: undefined,
        shipmentId: undefined,
        customer: undefined,
        unitCode: undefined,
        paymentDate: null,
        settlementDate: null,
        piDate: null,
        invoiceDate: null,
        receiptDate: null,
        bySettlement: undefined,
        bySalesOrder: undefined,
        byInvoiceAr: undefined,
        byArReceipt: undefined,
        sales: undefined,
      } as UjoTrackingReportState,
      customerOptions: [] as Option[],
      truckOptions: [] as Option[],
      loading: {
        find: false,
        download: false,
        customer: false,
        unitCode: false,
      },
      isShowAdvancedFilter: false,
      dataSource: [] as UjoTrackingReportDataView[],
      columns: [
        {
          title: this.$t("lbl_unit_code"),
          dataIndex: "unitCode",
          width: "150px",
        },
        {
          title: this.$t("lbl_serial_number"),
          dataIndex: "serialNumber",
        },
        {
          title: this.$t("lbl_equipment"),
          dataIndex: "equipment",
          width: "150px",
        },
        {
          title: this.$t("lbl_brand"),
          dataIndex: "brand",
          width: "150px",
        },
        {
          title: this.$t("lbl_type"),
          dataIndex: "type",
        },
        {
          title: this.$t("lbl_spec"),
          dataIndex: "spec",
        },
        {
          title: this.$t("lbl_driver_name"),
          dataIndex: "driver",
          customRender: text => text || "-",
        },
        {
          title: this.$t("lbl_customer"),
          dataIndex: "customer",
        },
        {
          title: this.$t("lbl_shipment_id"),
          dataIndex: "shipmentId",
        },
        {
          title: this.$t("lbl_ujo_number"),
          dataIndex: "ujoNumber",
        },
        {
          title: this.$t("lbl_sales_name"),
          dataIndex: "salesName",
        },
      ] as ColumnDef[],
      columnsSettlement: [
        {
          title: this.$t("lbl_payment_date"),
          dataIndex: "paymentDate",
          width: "150px",
          customRender: text => dateFormat(text),
        },
        {
          title: this.$t("lbl_payment_number"),
          dataIndex: "paymentNumber",
          width: "200px",
          customRender: text => text || "-",
        },
        {
          title: this.$t("lbl_doc_reference"),
          dataIndex: "docReference",
          width: "200px",
          customRender: text => text || "-",
        },
        {
          title: this.$t("lbl_payment_amount"),
          dataIndex: "paymentAmount",
          width: "200px",
          align: "right",
          customRender: text => currencyFilter(text),
        },
        {
          title: this.$t("lbl_deposit"),
          dataIndex: "deposit",
          width: "200px",
          align: "right",
          customRender: text => currencyFilter(text),
        },
        {
          title: this.$t("lbl_settlement_date"),
          dataIndex: "settlementDate",
          width: "150px",
          customRender: (text: string, _, index: number) =>
            index === 0 ? dateFormat(text) : "",
        },
        {
          title: this.$t("lbl_settlement_amount"),
          dataIndex: "settlementAmount",
          align: "right",
          customRender: (text: number, _, index: number) =>
            index === 0 ? currencyFilter(text) : "",
        },
      ] as ColumnDef[],
      columnsSalesOrder: [
        {
          title: this.$t("lbl_pi_date"),
          dataIndex: "piDate",
          customRender: text => dateFormat(text),
        },
        {
          title: this.$t("lbl_pi_number"),
          dataIndex: "piNumber",
          customRender: text => text || "-",
        },
        {
          title: this.$t("lbl_so_number"),
          dataIndex: "soNumber",
          customRender: text => text || "-",
        },
        {
          title: this.$t("lbl_dpp"),
          dataIndex: "soAmount",
          align: "right",
          customRender: text => currencyFilter(text),
        },
      ] as ColumnDef[],
      columnsInvoice: [
        {
          title: this.$t("lbl_invoice_date"),
          dataIndex: "invoiceDate",
          customRender: text => dateFormat(text),
        },
        {
          title: this.$t("lbl_invoice_number"),
          dataIndex: "invoiceNumber",
          customRender: text => text || "-",
        },
        {
          title: this.$t("lbl_dpp"),
          dataIndex: "amount",
          align: "right",
          customRender: text => currencyFilter(text),
        },
        {
          title: this.$t("lbl_status"),
          dataIndex: "status",
          customRender: text => toTitlecase(text) || "-",
        },
      ] as ColumnDef[],
      columnsInvoiceReceipt: [
        {
          title: this.$t("lbl_receipt_date"),
          dataIndex: "invoiceDate",
          customRender: text => dateFormat(text),
        },
        {
          title: this.$t("lbl_receipt_number"),
          dataIndex: "invoiceNumber",
          customRender: text => text || "-",
        },
        {
          title: this.$t("lbl_receipt_amount"),
          dataIndex: "amount",
          align: "right",
          customRender: text => currencyFilter(text),
        },
        {
          title: this.$t("lbl_status"),
          dataIndex: "status",
          customRender: text => toTitlecase(text) || "-",
        },
      ] as ColumnDef[],
      booleanOptions: [
        {
          value: 1,
          label: this.$t("lbl_yes"),
        },
        {
          value: 0,
          label: this.$t("lbl_no"),
        },
      ],
    };
  },
  mounted() {
    if (this.$refs.form) {
      this.form = this.$refs.form as FormModel;
    }

    this.getCustomerList();
    this.getTruckList();
  },
  methods: {
    async getCustomerList(
      params: RequestQueryParamsModel = new RequestQueryParams()
    ) {
      const { findCustomers, toOptions } = useContactData();
      try {
        this.loading.customer = true;
        const response = await findCustomers(params);
        this.customerOptions = toOptions(response.data);
      } finally {
        this.loading.customer = false;
      }
    },
    onSearchCustomer(value?: string) {
      const { filterBy } = useContactData();
      const params = new RequestQueryParams();
      if (value) {
        params.search = filterBy({ firstName: value, lastName: value });
      }
      this.getCustomerList(params);
    },
    async getTruckList(params?: RequestQueryParamsModel) {
      const { findAllTruck, toOptionsNew } = useAsset();
      try {
        this.loading.unitCode = true;
        const response = await findAllTruck(params);
        this.truckOptions = toOptionsNew(response.data);
      } finally {
        this.loading.unitCode = false;
      }
    },
    async onSearchTruck(value?: string) {
      const { filterBy } = useAsset();
      const params = new RequestQueryParams();
      if (value) {
        params.search = filterBy({ unitCode: value });
      }
      this.getTruckList(params);
    },
    buildDataView(): UjoTrackingReportDataView {
      const record: UjoTrackingReportDataView = {
        key: generateUUID(),
        unitCode: "",
        serialNumber: "",
        equipment: "",
        brand: "",
        type: "",
        spec: "",
        driver: "",
        customer: "",
        shipmentId: "",
        ujoNumber: "",
        salesName: "",
        settlements: [],
        salesOrders: [],
        invoiceAr: [],
        invoiceReceipts: [],
      };
      return record;
    },
    toDataView(dto: UjoTrackingReportDto, dataView: UjoTrackingReportDataView) {
      dataView.unitCode = dto.unitCode;
      dataView.serialNumber = dto.serialNo;
      dataView.equipment = dto.equipment;
      dataView.brand = dto.merk;
      dataView.type = dto.type;
      dataView.spec = dto.specification;
      dataView.driver = dto.driverName;
      dataView.customer = dto.customerName;
      dataView.shipmentId = dto.shipmentId;
      dataView.ujoNumber = dto.ujoNumber;
      dataView.salesName = dto.sales;

      const settlementExist = dataView.settlements.find(
        item => item.paymentNumber === dto.paymentNumber
      );
      if (!settlementExist) {
        dataView.settlements.push({
          key: generateUUID(),
          paymentDate: dto.paymentDate,
          paymentNumber: dto.paymentNumber,
          docReference: dto.cashInOutNumber,
          paymentAmount: dto.paymentAmount,
          deposit: dto.paymentDeposit,
          settlementDate: dto.settlementDate,
          settlementAmount: dto.settlementTotal,
        });
      }

      if (
        dto.piDate ||
        dto.piNumber ||
        dto.soNumber ||
        dto.soDpp ||
        dto.soLineId
      ) {
        const salesOrderExist = dataView.salesOrders.find(
          item => item.soLineId === String(dto.soLineId)
        );
        if (!salesOrderExist) {
          dataView.salesOrders.push({
            key: generateUUID(),
            piDate: dto.piDate ?? "",
            piNumber: dto.piNumber ?? "",
            soNumber: dto.soNumber ?? "",
            soAmount: dto.soDpp ?? 0,
            soLineId: String(dto.soLineId) ?? "",
          });
        }
      }

      if (
        dto.invoiceDate ||
        dto.invoiceNumber ||
        dto.invoiceDpp ||
        dto.invoiceStatus
      ) {
        const invoiceArExisted = dataView.invoiceAr.find(
          item => item.invoiceLineId === String(dto.invoiceLineId)
        );
        if (!invoiceArExisted) {
          dataView.invoiceAr.push({
            key: generateUUID(),
            invoiceDate: dto.invoiceDate ?? "",
            invoiceNumber: dto.invoiceNumber ?? "",
            amount: dto.invoiceDpp ?? 0,
            status: dto.invoiceStatus ?? "",
            invoiceLineId: String(dto.invoiceLineId) ?? "",
          });
        }
      }

      if (
        dto.receiptDate ||
        dto.receiptNumber ||
        dto.receiptAmount ||
        dto.receiptStatus
      ) {
        const invoiceArReceiptExisted = dataView.invoiceReceipts.find(
          item => item.invoiceLineId === String(dto.arReceiptLineId)
        );
        if (!invoiceArReceiptExisted) {
          dataView.invoiceReceipts.push({
            key: generateUUID(),
            invoiceDate: dto.receiptDate ?? "",
            invoiceNumber: dto.receiptNumber ?? "",
            amount: dto.receiptAmount ?? 0,
            status: dto.receiptStatus ?? "",
            invoiceLineId: String(dto.arReceiptLineId) ?? "",
          });
        }
      }
    },
    toUjoTrackingReportDataView(
      data: UjoTrackingReportDto[]
    ): UjoTrackingReportDataView[] {
      let settlement: Record<string, UjoTrackingReportDataView> = {};
      for (const doc of data) {
        if (!(doc.shipmentId in settlement)) {
          const record = this.buildDataView();
          settlement[doc.shipmentId] = record;
        }

        this.toDataView(doc, settlement[doc.shipmentId]);
      }

      const result: UjoTrackingReportDataView[] = [];
      for (const doc in settlement) {
        result.push(settlement[doc]);
      }

      return result;
    },
    async getReport(params?: RequestQueryParamsModel) {
      try {
        this.loading.find = true;
        const response = await ujoTrackingReportService.getList(params);
        this.dataSource = this.toUjoTrackingReportDataView(response);
      } finally {
        this.loading.find = false;
      }
    },
    toQueryDate(state: Moment[] | null, field: string): string | null {
      if (!state || state.length < 2) {
        return null;
      }

      const { toStartDay, toEndDay } = useDate();
      const [start, end] = state;
      return new SearchBuilder()
        .push([field, toStartDay(start).format()], { het: true })
        .and()
        .push([field, toEndDay(end).format()], { let: true })
        .build();
    },
    toQueryField(
      state: UjoTrackingReportState,
      field: Record<string, string>
    ): string {
      const queries: string[] = [];
      for (const key in field) {
        if (state[key] && key === "customer") {
          queries.push(
            new SearchBuilder()
              .push([field[key], state[key]?.key ?? ""])
              .build()
          );
        } else if (state[key] && key === "unitCode") {
          queries.push(
            new SearchBuilder()
              .push([field[key], state[key]?.label ?? ""], { like: "both" })
              .build()
          );
        } else if (state[key]) {
          queries.push(
            new SearchBuilder()
              .push([field[key], state[key]], { like: "both" })
              .build()
          );
        }
      }

      return queries.join(SearchBuilder.AND);
    },
    toQueryBoolean(
      state: UjoTrackingReportState,
      field: Record<string, string>
    ): string {
      const queries: string[] = [];
      for (const key in field) {
        if (state[key] !== undefined) {
          const NO = 0;
          const isNo = state[key] === NO;
          queries.push(
            new SearchBuilder()
              .push([field[key], "null"], { not: !isNo })
              .build()
          );
        }
      }
      return queries.join(SearchBuilder.AND);
    },
    toListParams(state: UjoTrackingReportState): RequestQueryParamsModel {
      const params = new RequestQueryParams();
      const queries: string[] = [];

      const field = {
        ujoNumber: "ujoNumber",
        shipmentId: "shipmentId",
        customer: "customerId",
        unitCode: "unitCode",
        sales: "sales",
      };
      const queryField = this.toQueryField(state, field);
      if (queryField) queries.push(queryField);

      const dateKeys: string[] = [
        "paymentDate",
        "settlementDate",
        "piDate",
        "invoiceDate",
        "receiptDate",
      ];
      for (const key of dateKeys) {
        const dateRange = this.toQueryDate(state[key], key);
        if (dateRange) queries.push(dateRange);
      }

      const booleanField = {
        bySettlement: "shipmentId",
        bySalesOrder: "soNumber",
        byInvoiceAr: "invoiceNumber",
        byArReceipt: "receiptNumber",
      };
      const queryBoolean = this.toQueryBoolean(this.state, booleanField);
      if (queryBoolean) queries.push(queryBoolean);

      params.search = queries.join(SearchBuilder.AND);
      return params;
    },
    toParamDate(
      state: UjoTrackingReportState,
      key: keyof Pick<
        UjoTrackingReportState,
        | "paymentDate"
        | "settlementDate"
        | "receiptDate"
        | "invoiceDate"
        | "piDate"
      >
    ): string {
      const { toDefaultFormat } = useDate();
      let value = "ALL";
      if (state[key] && state[key]?.length === 2) {
        const [start, end] = state[key] ?? [];
        value = `${toDefaultFormat(start)} - ${toDefaultFormat(end)}`;
      }
      return value;
    },
    toDownloadParams(state: UjoTrackingReportState): RequestQueryParamsModel {
      const params: RequestQueryParamsModel = this.toListParams(state);

      const company =
        this.$store.state.authStore.authData.companyName ??
        "PT. SATRIA PIRANTI PERKASA";

      const paymentDate = this.toParamDate(state, "paymentDate");
      const settlementDate = this.toParamDate(state, "settlementDate");
      const piDate = this.toParamDate(state, "piDate");
      const invoiceArDate = this.toParamDate(state, "invoiceDate");
      const receiptDate = this.toParamDate(state, "receiptDate");

      const booleanNumber = {
        1: this.$t("lbl_yes").toString(),
        0: this.$t("lbl_no").toString(),
      };
      const bySettlement =
        this.state.bySettlement === undefined
          ? "ALL"
          : booleanNumber[this.state.bySettlement];
      const bySalesOrder =
        this.state.bySalesOrder === undefined
          ? "ALL"
          : booleanNumber[this.state.bySalesOrder];
      const byInvoiceAr =
        this.state.byInvoiceAr === undefined
          ? "ALL"
          : booleanNumber[this.state.byInvoiceAr];
      const byArReceipt =
        this.state.byArReceipt === undefined
          ? "ALL"
          : booleanNumber[this.state.byArReceipt];

      const header: string[] = [
        company,
        this.state.ujoNumber || "ALL",
        this.state.shipmentId || "ALL",
        this.state.unitCode?.label || "ALL",
        this.state.customer?.label || "ALL",
        paymentDate,
        settlementDate,
        piDate,
        invoiceArDate,
        receiptDate,
        bySettlement,
        bySalesOrder,
        byInvoiceAr,
        byArReceipt,
        this.state.sales || "ALL",
      ];
      params.params = header.join(",");
      return params;
    },
    handleSubmit() {
      const params: RequestQueryParamsModel = this.toListParams(this.state);
      this.getReport(params);
    },
    async handleDownload() {
      const { toDownload } = useBlob();
      try {
        const params: RequestQueryParamsModel = this.toDownloadParams(
          this.state
        );
        this.loading.download = true;
        const response = await ujoTrackingReportService.download(params);
        toDownload(response, "ujo_tracking_report.xlsx");
      } finally {
        this.loading.download = false;
      }
    },
    handleReset() {
      this.form.resetFields();
      this.state.paymentDate = null;
      this.state.receiptDate = null;
      this.state.invoiceDate = null;
      this.state.piDate = null;
      this.state.settlementDate = null;

      this.getCustomerList();
      this.getTruckList();
    },
    toggleAdvancedFilter(show: boolean) {
      this.isShowAdvancedFilter = show;
    },
    onChangeUnit(value?: LabelInValue) {
      if (!value) {
        this.getTruckList();
      }
    },
    onChangeCustomer(value?: LabelInValue) {
      if (!value) {
        this.getCustomerList();
      }
    },
  },
});
