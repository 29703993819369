


























/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { debounce } from "@/helpers/debounce";
import { ContactData } from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { contactServices } from "@/services/contact.service";
import { Component, Vue } from "vue-property-decorator";

const CustomerSelectProps = Vue.extend({
  props: {
    value: {
      type: String,
    },
    customerName: {
      type: String,
    },
    customerId: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
  },
});

@Component({
  watch: {
    customerId: {
      immediate: true,
      deep: true,
      handler() {
        this.handleCustomerNameAndCustomerId();
      },
    },
    customerName: {
      immediate: true,
      deep: true,
      handler() {
        this.handleCustomerNameAndCustomerId();
      },
    },
    "customerOption.data": {
      immediate: true,
      deep: true,
      handler() {
        this.handleCustomerNameAndCustomerId();
      },
    },
  },
})
export default class CustomerSelect extends CustomerSelectProps {
  customerOption = {
    data: [] as ContactData[],
    search: "",
    fetching: true,
    initialData: [] as ContactData[],
  };

  async created() {
    await this.getCustomerList(true);
    if (
      this.value &&
      !this.customerOption.data.find(c => c.id === this.value)
    ) {
      this.fetchMoreCustomer();
    }
  }

  async getCustomerList(firstLoad = false) {
    try {
      this.customerOption.fetching = true;
      const param: RequestQueryParamsModel = {
        limit: 20,
        page: 0,
        search: `customer~true`,
      };
      if (this.customerOption.search) {
        param.search = `firstName~*${this.customerOption.search}*_OR_lastName~*${this.customerOption.search}*_OR_phoneNumber~*${this.customerOption.search}*_OR_email~*${this.customerOption.search}*_OR_customerNumber~*${this.customerOption.search}*_AND_customer~true`;
      }
      const res = await contactServices.listContactData(param);
      this.$emit("on-getCustomerList", res.data);
      this.customerOption.data = res.data;
      if (firstLoad) {
        this.customerOption.initialData = res.data;
      }
      this.customerOption.fetching = false;
    } catch (error) {
      this.customerOption.fetching = false;
    }
  }

  searchCustomer(value: string): void {
    debounce(() => {
      this.customerOption.search = value;
      this.getCustomerList();
    });
  }

  handleChange(e: string): void {
    if (e) {
      this.getCustomerDetail(e);
    }
    this.$emit("input", e);
    this.$emit("change", e);
  }

  getCustomerDetail(customerId: string): void {
    const selectedCustomer = this.customerOption.data.find(
      d => d.id === customerId
    );
    if (selectedCustomer) {
      this.$emit("changeCustomer", selectedCustomer);
    } else {
      this.$emit("changeCustomer", null);
    }
  }

  resetOptionList(): void {
    this.customerOption.data = this.customerOption.initialData;
  }

  /**
   * Fetch customer list from API until matching with @this value
   */
  async fetchMoreCustomer() {
    try {
      this.customerOption.fetching = true;
      let totalPage = 1;
      const param = {
        limit: 20,
        page: 1,
        search: `customer~true`,
      };
      while (
        !this.customerOption.data.find(c => c.id === this.value) &&
        param.page <= totalPage
      ) {
        const customerOptions = await contactServices.listContactData(param);
        totalPage = Math.ceil(customerOptions.totalElements / 20);
        this.customerOption.data = this.customerOption.data.concat(
          customerOptions.data
        );
        param.page++;
      }
      this.$emit("on-getCustomerList", this.customerOption.data);
      this.customerOption.fetching = false;
    } catch (error) {
      this.customerOption.fetching = false;
    }
  }

  handleCustomerNameAndCustomerId() {
    if (
      this.customerName &&
      this.customerId &&
      !this.customerOption.data.find(c => c.id === this.customerId)
    ) {
      this.customerOption.data = [
        ...this.customerOption.data,
        { id: this.customerId, fullName: this.customerName },
      ].filter(
        (value, index, self) => index === self.findIndex(t => t.id === value.id)
      );
    }
  }
}
